.portal-header-component {
    padding-top: 25px;
}

.client-portal-sidebar-container {
    background-color: #f5f5f5;
    min-height: 100vh;
}

.dashboard-container-row {
    border-top: 1px solid var(--border-color);
    padding-top: 10px;
    padding-bottom: 10px;
}

.download-agent-row {
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    padding-top: 10px;
    padding-bottom: 10px;
}

.buy-more[data-badge]:after,
.insurance-activation[data-badge]:after {
    content: attr(data-badge);
    display: inline-block;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: none;
    background-color: var(--primary-color);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .25);
    color: var(--white-color);
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    align-content: center;
}

.card-information-container {
    background-color: #f5f5f5;
    padding: 30px;
    border-radius: 15px;

    .information-row {
        border-bottom: 1px solid var(--border-color);
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.form-input-container {
    .form-heading-container {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.4em;
        color: var(--default-text-color);
    }
}

.input-required {
    border: 2px solid var(--primary-color) !important;
}

.btn-download-link {
    font-size: 14px;
    line-height: 1.2em;
    letter-spacing: 0;
    word-spacing: 0;
    font-weight: 600;
    color: var(--secondary-color);
    background-color: transparent;
    border: 0;
}

.download-link-unavailable {
    font-size: 14px;
    line-height: 1.2em;
    letter-spacing: 0;
    word-spacing: 0;
    font-weight: 600;
    color: var(--secondary-color);
}

.btn-download-link:hover,
.btn-download-link:focus {
    text-decoration: underline;
}

.delete-insurance-line {
    font-size: 25px;
    color: var(--default-text-color);
}

.important-information {
    color: var(--primary-color) !important;
    font-weight: 600;
}

/* -- Media Queries -- */
@media (max-width: 576px) {
    .logo-sidebar {
        padding-top: 12px;
    }

    .download-agent-image {
        font-size: 40px;
    }

    .action-container {
        a {
            margin-bottom: 16px;
            width: 100%;
        }
    }
}

/* -- Medium Screen Breakpoint -- */
@media (min-width: 768px) {
    .download-agent-image {
        font-size: 50px;
    }

    .action-container {
        a {
            margin-bottom: 16px;
            width: 100%;
        }
    }
}

/* -- Large Screen Breakpoint -- */
@media (min-width: 992px) {
    .portal-nav-item {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .download-agent-image {
        font-size: 50px;
    }

    .action-container {
        a {
            margin-bottom: 16px;
            width: fit-content;
        }
    }
}

/* -- X-Large Screen Breakpoint -- */
@media (min-width: 1200px) {
    .portal-nav-item {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .download-agent-image {
        font-size: 70px;
    }

    .action-container {
        a {
            margin-bottom: 16px;
            width: fit-content;
        }
    }
}

/* -- XX-Large Screen Breakpoint -- */
@media (min-width: 1400px) {
    .portal-nav-item {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .download-agent-image {
        font-size: 70px;
    }

    .action-container {
        a {
            margin-bottom: 16px;
            width: fit-content;
        }
    }
}