.Blog-Detail-Image {
    border-radius: 5px;
    margin-bottom: 20px;
}

.Blog-Detail-Date-Time {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5em;
    color: #ADADAD;
    margin-bottom: 20px;
}


.blog-detail-heading {
    text-align: center;
}

.blog-detail-content {
    text-align: left;
    font-family: var(--font-family);

    img {
        max-width: 100%;
        height: auto;
    }

    blockquote {
        color: var(--white-color) !important;
        padding: 30px;
        background-color: var(--secondary-color);
        font-size: 30px;
        font-weight: 400;
        line-height: 1.4em;

        span {
            color: var(--white-color) !important;
            font-family: var(--font-family);
        }
    }

    p, span, ul, li {
        font-family: var(--font-family);
    }
}
/* CommentSection.css */

.comment-form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin-bottom: 40px;
  }
  
  .comment-form-container h3 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
  }
  
  .comment-form-container form {
    display: flex;
    flex-direction: column;
  }
  
  .comment-form-container input,
  .comment-form-container textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .comment-form-container input:focus,
  .comment-form-container textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .comment-form-container button {
    width: 100%;
    padding: 10px;
    background-color: var(--secondary-color);
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .comment-form-container button:hover {
    background-color:var(--secondary-color);
  }
  
  .comment-list {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f1f1f1;
  }
  
  .comment-list h4 {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .comment-item {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
  }
  
  .comment-item:last-child {
    border-bottom: none;
  }
  
  .comment-author {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .comment-author img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  
  .comment-author strong {
    font-size: 16px;
  }
  
  .comment-author small {
    font-size: 14px;
    color: #666;
    margin-left: 5px;
  }
  
  .comment-content {
    font-size: 16px;
  }
  
