.shop-header-container {
    margin-bottom: 15px;
}

.shop-filters-container {
    color: var(--default-text-color);
    font-size: 16px;
}

.select-component {
    padding-right: 16px;

    .select-toggle {
        font-size: 16px;
        color: var(--default-text-color);
        border: 1px solid var(--border-color);
        padding: 5px 16px;
        border-radius: 5px;
        background-color: #FFFFFF;
        text-transform: capitalize;
    }

    .select-menu {
        margin-top: 5px !important;
    }
}

.clear-filters-container {
    .clear-filters {
        font-weight: 600;
        color: var(--secondary-color);
        text-decoration: none;
    }
}

.product-card-container {
    background-color: var(--secondary-color) !important;
    border-radius: 7px !important;
    color: var(--white-color);

    .product-card-body {
        background-color: inherit !important;
    }

    .product-icon-container {
        display: inline-block !important;
        border-radius: 50%;
        background: white;
        padding: 20px;
        margin-bottom: 25px;

        img {
            max-height: 80px;
        }
    }

    .product-name-container {
        font-size: 28px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .product-price-container {
        font-size: 35px;
        font-weight: 100;
        line-height: 30px;
        margin-bottom: 10px;

        .product-price-information-container {
            display: block;
            font-size: 13px;
            line-height: 30px;
        }
    }

    .product-inherited-features-container {
        display: inline-block;
        background: var(--primary-color);
        border-radius: 15px;
        font-size: 14px;
        margin-bottom: 15px;
    }

    .product-inherited-features-spacing {
        padding: 25px;
    }

    .product-features-container {
        font-size: 14px;
        text-align: left;

        p, ul {
            margin-bottom: 0;
        }
    }

    .product-footer-container {
        border-top: 0;
        background-color: inherit !important;
    }
}

.product-card-container:focus,
.product-card-container:hover {
    background-color: var(--primary-color) !important;

    .product-inherited-features-container {
        background: var(--secondary-color);
    }
}

.on-promotion-badge {
    background: #D9D9D97F;
    padding: 5px;
    font-weight: 600;
    display: inline-block;
    border-radius: 15px;
    font-size: 14px;
}

.on-promotion {
    text-decoration: line-through;
    margin-right: 5px;
    color: #D9D9D97F;
}

.promotion-spacing {
    padding: 16px;
}

.message-container {
    position: sticky;
    z-index: 1;
    top: 15%;
}

/* -- Media Queries -- */
@media (max-width: 576px) {
    .shop-filters-container {
        display: block;
    }

    .select-component {
        padding-bottom: 16px;
    }

    .product-card-body {
        padding: 10px !important;
    }

    .product-inherited-features-container {
        padding: 5px 15px;
    }

    .product-price-spacing {
        padding: 15px;
    }
}

/* -- Medium Screen Breakpoint -- */
@media (min-width: 768px) {
    .product-inherited-features-container {
        padding: 5px 15px;
    }

    .product-price-spacing {
        padding: 15px;
    }
}

/* -- Large Screen Breakpoint -- */
@media (min-width: 992px) {
    .product-inherited-features-container {
        padding: 5px 10px !important;
    }

    .product-price-spacing {
        padding: 15px;
    }
}

/* -- X-Large Screen Breakpoint -- */
@media (min-width: 1200px) {
    .product-inherited-features-container {
        padding: 5px 15px;
    }

    .product-price-spacing {
        padding: 15px;
    }
}

/* -- XX-Large Screen Breakpoint -- */
@media (min-width: 1400px) {
    .product-inherited-features-container {
        padding: 5px 15px;
    }

    .product-price-spacing {
        padding: 15px;
    }
}