.icon-container {
    border: 1px solid var(--border-color);
    border-radius: 10px;
}

.name-container {
    color: var(--default-text-color);
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.category-container {
    color: var(--default-text-color);
    font-weight: 600;
    margin-bottom: 10px;
    margin-right: 5px;

    .category {
        margin-left: 5px;
        font-weight: 400;
    }
}

.delete-product {
    color: var(--default-text-color);
    font-size: 20px;
    text-decoration: none;
    cursor: pointer;
}

.price-container {
    line-height: 1;
    font-weight: 400;

    .price-indicator {
        margin-left: 5px;
    }
}

.total-price-container {
    font-weight: 600;
}

.order-summary-container {
    color: var(--default-text-color);
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
}

.summary-heading-container {
    color: var(--default-text-color);
    font-size: 16px;
    font-weight: 600;
}

.summary-price-container {
    color: var(--default-text-color);
    font-size: 16px;
    font-weight: 600;
}

.voucher-text-color {
    color: #777777 !important;
}

.checkout-inputs {
    border-radius: 5px;
    border: 1px solid #ddd;
}

.empty-message {
    color: var(--default-text-color);
    font-size: 16px;
}

.email-validation {
    color: var(--paragraph-text-color);
    font-size: 16px;
}

.email-validation-error {
    margin-top: 10px;
    color: var(--primary-color);
    font-size: 16px;
    font-weight: 500;
}

.basket-row-items:last-child {
    border-bottom: 0 !important;
}

.checkout-confirmation-description-container {
    font-size: 1.125rem;
    line-height: 1.55;
    margin-bottom: 1.875rem;
}

/* -- Media Queries -- */
@media (max-width: 576px) {
    .checkout-inputs {
        font-size: 14px;
        padding: 5px 10px;
    }

    .c-mb-16 {
        margin-bottom: 16px;
    }

    .name-container {
        font-size: 14px;
    }

    .category-container {
        font-size: 14px;
    }

    .price-container {
        font-size: 14px;
        margin-bottom: 10px;

        .price-indicator {
            font-size: 13px;
        }
    }

    .total-price-container {
        font-size: 14px;
    }
}

/* -- Medium Screen Breakpoint -- */
@media (min-width: 768px) {
    .checkout-inputs {
        font-size: 16px;
        padding: 10px 20px;
    }

    .c-mb-16 {
        margin-bottom: 16px;
    }

    .name-container {
        font-size: 14px;
    }

    .category-container {
        font-size: 14px;
    }

    .price-container {
        font-size: 14px;

        .price-indicator {
            font-size: 13px;
        }
    }

    .total-price-container {
        font-size: 14px;
    }
}

/* -- Large Screen Breakpoint -- */
@media (min-width: 992px) {
    .row-spacing {
        padding-right: 100px !important;
    }

    .checkout-inputs {
        font-size: 16px;
        padding: 10px 20px;
    }

    .name-container {
        font-size: 16px;
    }

    .category-container {
        font-size: 16px;
    }

    .price-container {
        font-size: 16px;

        .price-indicator {
            font-size: 14px;
        }
    }

    .total-price-container {
        font-size: 16px;
    }

    .c-mb-16 {
        margin-bottom: 0;
    }
}

/* -- X-Large Screen Breakpoint -- */
@media (min-width: 1200px) {
    .row-spacing {
        padding-right: 100px !important;
    }

    .checkout-inputs {
        font-size: 16px;
        padding: 10px 20px;
    }

    .name-container {
        font-size: 16px;
    }

    .category-container {
        font-size: 16px;
    }

    .price-container {
        font-size: 16px;

        .price-indicator {
            font-size: 14px;
        }
    }

    .total-price-container {
        font-size: 16px;
    }

    .c-mb-16 {
        margin-bottom: 0;
    }
}

/* -- XX-Large Screen Breakpoint -- */
@media (min-width: 1400px) {
    .row-spacing {
        padding-right: 100px !important;
    }

    .checkout-inputs {
        font-size: 16px;
        padding: 10px 20px;
    }

    .name-container {
        font-size: 16px;
    }

    .category-container {
        font-size: 16px;
    }

    .price-container {
        font-size: 16px;

        .price-indicator {
            font-size: 14px;
        }
    }

    .total-price-container {
        font-size: 16px;
    }

    .c-mb-16 {
        margin-bottom: 0;
    }
}