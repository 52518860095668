.Heading {
    text-align: center;
}

.Description {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5rem;
    color: var(--paragraph-text-color)
}

.about-us-heading {
    padding: 7px 9px 6px;
    border-radius: 5px;
    background-color: var(--default-badge-background-color);
    color: var(--default-text-color);
    font-size: 12px;
    font-weight: 600;
    line-height: 1.7em;
}

.about-us-description {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5em;
    color: #666666;
}

.company-experience-container {
    background-color: var(--secondary-color);
}

.company-team-main-container {
    color: var(--white-color);

    padding-bottom: 30px;
    border-bottom: 2px solid var(--white-color);

    .company-team-heading-container {
        margin-top: 20px;
    }

    .company-team-description-container {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.5em;
        margin-top: 20px;
    }
}

.company-experience-icon-container {
    margin-bottom: 20px;

    img {
        max-height: 70px;
        background-color: var(--white-color);
        padding: 15px;
        border-radius: 50%;
    }
}

.company-experience-heading-container {
    color: var(--white-color);
}

.member-card-container {
    border: 0 !important;
}

.member-name-container h4 {
    margin-bottom: 5px;
    font-size: 1.2rem;
    color: var(--default-text-color);
}

.member-card-body-container {
    padding: 0 !important;
}

.member-image-container {
    margin-bottom: 10px;

    img {
        border-radius: 5px;
    }
}

.member-position-container {
    margin-top: auto;
    margin-bottom: 10px;
}

.member-details-container {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
}

.member-details {
    padding-top: 20px;
    color: var(--default-text-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2em;
}

.member-details-socials {
    font-size: 35px;
}

.member-card-footer-container {
    border-top: 0 !important;
    padding: 0 !important;
}

.member-view-more {
    font-size: 14px;
    line-height: 1.7em;
    font-weight: 600;
    text-decoration: none;
    color: var(--default-text-color);
    cursor: pointer;
}

.company-experience-description-container {
    font-size: 14px;
    line-height: 1.4em;
    margin-bottom: 14px;
    color: var(--white-color);
}

.support-description {
    font-size: 18px;
    line-height: 1.5em;
}

/* -- Media Queries -- */
@media (max-width: 576px) {
    .about-us-heading {
        margin-bottom: 15px;
    }

    .company-experience-heading-container {
        margin-bottom: 15px;
    }

    .company-team-main-container {
        margin-bottom: 20px;
    }

    .timeline::before,
    .timeline ul li::after {
        display: none;
    }

    .timeline ul li {
        flex-direction: column;
        align-items: flex-start;
    }

    .timeline ul li .content {
        order: 1;
        text-align: left;
    }

    .timeline ul li .time {
        order: 2;
        text-align: left;
    }

    .team-member-heading-container {
        margin-bottom: 30px;
    }
}

/* -- Medium Screen Breakpoint -- */
@media (min-width: 768px) {
    .about-us-heading {
        margin-bottom: 20px;
    }

    .company-experience-heading-container {
        margin-bottom: 20px;
    }
}

/* -- Large Screen Breakpoint -- */
@media (min-width: 992px) {
    .about-us-heading {
        margin-bottom: 20px;
    }

    .company-experience-heading-container {
        margin-bottom: 20px;
    }
}

/* -- X-Large Screen Breakpoint -- */
@media (min-width: 1200px) {
    .about-us-heading {
        margin-bottom: 20px;
    }

    .company-experience-heading-container {
        margin-bottom: 20px;
    }
}

/* -- XX-Large Screen Breakpoint -- */
@media (min-width: 1400px) {
    .about-us-heading {
        margin-bottom: 20px;
    }

    .company-experience-heading-container {
        margin-bottom: 20px;
    }
}

.timeline-container {
    padding: 20px;
    position: relative;
}

.timeline {
    margin-top: 20px;
    position: relative;
}

.timeline ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.timeline ul li {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px 0;
}

.timeline-item-left .content {
    flex: 1;
    order: 1;
    text-align: right;
    padding-right: 20px;
}

.timeline-item-left .time {
    flex: 1;
    order: 2;
    text-align: left;
    padding-left: 20px;
}

.timeline-item-right .content {
    flex: 1;
    order: 2;
    text-align: left;
    padding-left: 20px;
}

.timeline-item-right .time {
    flex: 1;
    order: 1;
    text-align: right;
    padding-right: 20px;
}

.timeline ul li .content h2 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 16px;
}

.timeline ul li .content p {
    margin-bottom: 0;
    text-align: left;
}

.timeline ul li .time img.Image {
    max-width: 100%;
    max-height: 150px;
    height: auto;
    border-radius: 8px;
}

.timeline::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 2px;
    background-color: #00000026;
    transform: translateX(-50%);
}

.timeline ul li::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #002868;
}
  