.product-information-container,
.product-benefits-container,
.product-features-main-container,
.product-automation-container {
    background: #ececec;
}

.information-product-name {
    margin-bottom: 25px;

    .h5 {
        color: #B22234 !important;
    }
}

.information-heading {
    margin-bottom: 25px;
}

.information-description {
    font-size: 18px;
}

.product-image {
    max-height: 350px;
}

.benefits-main-container {
    background: var(--white-color);
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.product-feature-heading {
    margin-bottom: 15px;

    .h3 {
        color: #002868 !important;
    }
}

.product-feature-image {
    border-radius: 5px;
    margin-bottom: 15px;
}

.product-feature-description {
    font-size: 18px;
    margin-bottom: 15px;
}

.automation-icon-container
{
    height: 65px;
    width: 62px;
}

.feature-view-more {
    font-size: 14px;
    line-height: 1.7em;
    font-weight: 600;
    text-decoration: none;
    color: var(--primary-color);
    cursor: pointer;
    border: 0;
    padding: 0;
    background: transparent;
}

.feature-view-more:hover,
.feature-view-more:focus {
    text-decoration: underline;
}

/* -- Media Queries -- */
@media (max-width: 576px) {
    .automation-icon-container {
        max-height: 100px !important;
    }

    .automation-content {
        text-align: center;
    }

    .automation-icon-main-container {
        margin-bottom: 15px !important;
    }

    .automation-desktop-image-container {
        display: none;
    }

    .automation-mobile-image-container {
        display: block;
    }
}

/* -- Medium Screen Breakpoint -- */
@media (min-width: 768px) {
    .row:nth-child(even) .product-feature-container:first-child {
        order: 2;
    }

    .row:nth-child(even) .product-feature-container:last-child {
        order: 1;
    }

    .automation-icon-container {
        max-height: 100px !important;
    }

    .automation-content {
        display: inline-flex;
        align-items: center;
        justify-content: start;
        text-align: left;
    }

    .automation-icon-main-container {
        margin-right: 6px;
    }

    .automation-desktop-image-container {
        display: none;
    }

    .automation-mobile-image-container {
        display: block;
    }
}

/* -- Large Screen Breakpoint -- */
@media (min-width: 992px) {
    .row:nth-child(even) .product-feature-container:first-child {
        order: 2;
    }

    .row:nth-child(even) .product-feature-container:last-child {
        order: 1;
    }

    .automation-icon-container {
        max-height: 70px !important;
    }

    .automation-content {
        display: inline-flex;
        align-items: center;
        justify-content: start;
        text-align: left;
    }

    .automation-icon-main-container {
        margin-right: 6px;
    }

    .automation-desktop-image-container {
        display: block;
    }

    .automation-mobile-image-container {
        display: none;
    }
}

/* -- X-Large Screen Breakpoint -- */
@media (min-width: 1200px) {
    .row:nth-child(even) .product-feature-container:first-child {
        order: 2;
    }

    .row:nth-child(even) .product-feature-container:last-child {
        order: 1;
    }

    .automation-icon-container {
        max-height: 70px !important;
    }

    .automation-icon-main-container {
        margin-right: 16px;
    }

    .automation-content {
        display: inline-flex;
        align-items: center;
        justify-content: start;
    }

    .automation-desktop-image-container {
        display: block;
    }

    .automation-mobile-image-container {
        display: none;
    }
}

/* -- XX-Large Screen Breakpoint -- */
@media (min-width: 1400px) {
    .row:nth-child(even) .product-feature-container:first-child {
        order: 2;
    }

    .row:nth-child(even) .product-feature-container:last-child {
        order: 1;
    }

    .automation-icon-container {
        max-height: 70px !important;
    }

    .automation-icon-main-container {
        margin-right: 16px;
    }

    .automation-content {
        display: inline-flex;
        align-items: center;
        justify-content: start;
    }

    .automation-desktop-image-container {
        display: block;
    }

    .automation-mobile-image-container {
        display: none;
    }
}