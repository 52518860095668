footer, .footer {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
}

.social-container {
    display: inline-grid;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    color: var(--default-text-color);
    padding: 15px;
}

.social-icons img {
    max-height: 70px;
    text-align: center;
}

.information-container {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--default-text-color);
    text-align: left;

    p {
        margin-bottom: 0;
        text-align: left;
    }

    a {
        color: var(--default-text-color);
        text-decoration: none;
        text-align: left;
    }
}

.information-links:focus,
.information-links:hover,
.shop-categories a:hover,
.shop-categories a:focus {
    text-decoration: underline;
}

.shop-categories-container {
    color: #FFFFFF;

    p {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 0;
    }
}

.certification-image {
    max-height: 140px;
    height: 140px;
    width: 140px;
}

.footer-logo-container,
.consultation-container {
    align-items: start;
    justify-content: center;
    display: grid;
}

.footer-logo {
    max-height: 112px;
    height: 112px;
    width: 190px;
}

.shop-categories a {
    color: #f5f5f5;
    text-decoration: none;
}

.mailing-list-container {
    color: #f5f5f5;

    .email-input > input {
        width: 70%;
        background-color: transparent;
        border: 1px solid #f5f5f5;
        border-radius: 5px;
        padding: 10px 15px;
        font-size: 14px;
    }

    .policy-confirmation {
        font-size: 12px;
    }
}

@media (max-width: 576px) {
    .information-container {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important;
    }

    .separator {
        height: 2px;
        width: 100%;
        background: var(--border-color);
        top: 0;
        position: absolute;
    }
}

/* -- Small Screen Breakpoint -- */
@media (min-width: 576px) {
    .separator {
        height: 2px;
        width: 100%;
        background: var(--border-color);
        top: 0;
        position: absolute;
    }
}

/* -- Medium Screen Breakpoint -- */
@media (min-width: 768px) {
    .separator {
        height: 2px;
        width: 100%;
        background: var(--border-color);
        top: 0;
        position: absolute;
    }
}

/* -- Large Screen Breakpoint -- */
@media (min-width: 992px) {
    .separator {
        height: 27px;
        width: 3px;
        background: var(--border-color);
        top: 25%;
        bottom: 25%;
        position: absolute;
        left: 0;
    }
}

/* -- X-Large Screen Breakpoint -- */
@media (min-width: 1200px) {
    .separator {
        height: 27px;
        width: 3px;
        background: var(--border-color);
        top: 25%;
        bottom: 25%;
        position: absolute;
        left: 0;
    }
}

/* -- XX-Large Screen Breakpoint -- */
@media (min-width: 1400px) {
    .separator {
        height: 27px;
        width: 3px;
        background: var(--border-color);
        top: 25%;
        bottom: 25%;
        position: absolute;
        left: 0;
    }
}