.banner-description {
    color: var(--default-text-color);
}

.banner-image-container img {
    border-radius: 5px;
}

.service-icon-container img {
    max-height: 70px;
    height: 70px;
    width: 61px;
}

.service-description-container {
    font-size: 14px;
    line-height: 1.4em;
    margin-bottom: 14px;
    color: var(--default-text-color);
}

.partners-description-container {
    font-size: 20px;
    line-height: 1.5em;
    margin-bottom: 20px;
}

/* .solutions-width
{
    width: 1900px;
    height: 1050px;
} */

@media (max-width: 576px) {
    .solutions-width
    {
        width: 100%;
        height: auto;
    }

}

.home-solutions-section {
    background: linear-gradient(180deg, #002868 0%, #E9E6EF 100%);
}

.solutions-main-container {
    color: var(--default-text-color);
    margin-bottom: 30px;

    .solutions-container {
        display: contents;
        color: var(--default-text-color);
        text-decoration: none;
    }
}

.solutions-heading-container {
    color: var(--white-color);
}

.icon-img-solutions
{
    width:62px;
    height: 65px;
}

.solutions-icon-section,
.solutions-heading-section {
    margin-bottom: 15px;

    img {
        max-height: 65px;       

    }
}

.solutions-description-section {
    font-size: 14px;
    line-height: 1.4em;
}

.solutions-call-to-action {
    font-size: 12px;
    line-height: 1.3em;
    font-weight: 600;
    color: var(--secondary-color);

    text-decoration-line: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: #dee0fe;
}

.solutions-call-to-action:active,
.solutions-call-to-action:hover,
.solutions-call-to-action:focus {
    color: var(--default-text-color);
    text-decoration-line: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: var(--secondary-color);
}

.home-vendors-section {
    background-color: #002868;
}

.vendor-title-container {
    margin-bottom: 10px;
}

.vendor-description-container {
    font-size: 14px;
    line-height: 1.4em;
}

.industries-icon-container {
    img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
}

.industries-title-container {
    font-size: 15px;
    line-height: 26px;
    font-weight: 600;
    margin-left: 10px;
}

.vendor-heading-container {
    color: var(--white-color);
}

.vendor-information-container {
    background: #FFFFFF;
    padding: 25px;
    max-width: 400px;
}

/* -- Media Queries -- */
@media (max-width: 576px) {
    .banner-heading-container {
        margin-bottom: 15px;
    }

    .banner-description {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .call-to-action-button-container {
        a {
            width: 100%;
            margin-bottom: 15px;
        }
    }

    .service-icon-container {
        margin-bottom: 15px;
    }

    .service-header-container {
        margin-bottom: 15px;
    }

    .partners-heading-container {
        margin-bottom: 15px;
    }

    .partners-images-main-container {
        min-height: 300px;
    }

    .partners-images-section-top-left {
        position: absolute;
        top: 0;
        left: 0;

        img {
            max-height: 73px
        }
    }

    .partners-images-section-top-middle {
        position: absolute;
        top: 5%;
        right: 30px;

        img {
            max-height: 28px
        }
    }

    .partners-images-section-top-right {
        position: absolute;
        right: 0;
        top: 15%;

        img {
            max-height: 29px
        }
    }

    .partners-images-section-mid-left {
        position: absolute;
        left: 0;
        top: 45%;

        img {
            max-height: 29px;
        }
    }

    .partners-images-section-mid-middle {
        position: absolute;
        top: 35%;
        right: 35%;

        img {
            max-height: 26px;
        }
    }

    .partners-images-section-mid-right {
        position: absolute;
        right: 0;
        top: 45%;

        img {
            max-height: 67px;
        }
    }

    .partners-images-section-bottom-left {
        position: absolute;
        bottom: 0;
        left: 10px;

        img {
            max-height: 66px;
        }
    }

    .partners-images-section-bottom-middle {
        position: absolute;
        bottom: 25%;
        right: 35%;

        img {
            max-height: 57px;
        }
    }

    .partners-images-section-bottom-right {
        position: absolute;
        bottom: 10%;
        right: 0;

        img {
            max-height: 27px;
        }
    }

    .solutions-heading-container {
        margin-bottom: 20px;
    }

    .vendor-heading-container {
        margin-bottom: 20px;
    }

    .industries-heading-container {
        margin-bottom: 20px;
    }

    .industries-container {
        margin-bottom: 20px;
    }

    .vendor-main-information-container {
        height: 500px;
    }

    .position-first {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    .position-second {
        position: absolute;
        top: 35%;
        left: 0;
        right: 0;
    }

    .position-last {
        position: absolute;
        bottom: 5%;
        left: 0;
        right: 0;
    }
}

/* -- Medium Screen Breakpoint -- */
@media (min-width: 768px) {
    .banner-heading-container {
        margin-bottom: 30px;
    }

    .banner-description {
        font-size: 20px;
        margin-bottom: 30px;
    }

    .call-to-action-button-container {
        display: inline-flex;
    }

    .service-icon-container {
        margin-bottom: 20px;
    }

    .service-header-container {
        margin-bottom: 20px;
    }

    .partners-heading-container {
        margin-bottom: 20px;
    }

    .partners-images-main-container {
        min-height: 400px;
    }

    .partners-images-section-top-left {
        position: absolute;
        top: 0;
        left: 0;

        img {
            max-height: 93px
        }
    }

    .partners-images-section-top-middle {
        position: absolute;
        right: 25%;
        top: 10%;

        img {
            max-height: 28px
        }
    }

    .partners-images-section-top-right {
        position: absolute;
        right: 0;
        top: 20%;

        img {
            max-height: 29px
        }
    }

    .partners-images-section-mid-left {
        position: absolute;
        left: 0;
        top: 45%;

        img {
            max-height: 29px;
        }
    }

    .partners-images-section-mid-middle {
        position: absolute;
        right: 40%;
        top: 40%;

        img {
            max-height: 26px;
        }
    }

    .partners-images-section-mid-right {
        position: absolute;
        right: 0;
        top: 40%;

        img {
            max-height: 77px;
        }
    }

    .partners-images-section-bottom-left {
        position: absolute;
        left: 10%;
        bottom: 0;

        img {
            max-height: 96px;
        }
    }

    .partners-images-section-bottom-middle {
        position: absolute;
        right: 10%;
        bottom: 15%;

        img {
            max-height: 67px;
        }
    }

    .partners-images-section-bottom-right {
        position: absolute;
        right: 0;
        bottom: 0;

        img {
            max-height: 37px;
        }
    }

    .solutions-heading-container {
        margin-bottom: 25px;
    }

    .vendor-heading-container {
        margin-bottom: 25px;
    }

    .industries-heading-container {
        margin-bottom: 50px;
    }

    .industries-container {
        margin-right: 20px;
        margin-bottom: 25px;
    }

    .vendor-main-information-container {
        background-image: url("/public/images/vendor-circle.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
        height: 500px;
    }

    .position-first {
        position: absolute;
        top: 5%;
        left: 0;
        box-shadow: 10px 10px 10px 0 #3B87AF;
    }

    .position-second {
        position: absolute;
        top: 35%;
        right: 5%;
        box-shadow: 10px 10px 10px 0 #3B87AF;
    }

    .position-last {
        position: absolute;
        bottom: 5%;
        left: 5%;
        box-shadow: 10px -10px 10px 0 #3B87AF;
    }
}

/* -- Large Screen Breakpoint -- */
@media (min-width: 992px) {

    
    .banner-heading-container {
        margin-bottom: 32px;
    }

    .banner-description {
        font-size: 32px;
        margin-bottom: 32px;
    }

    .call-to-action-button-container {
        display: inline-flex;
    }

    .service-icon-container {
        margin-bottom: 20px;
    }

    .service-header-container {
        margin-bottom: 20px;
    }

    .partners-heading-container {
        margin-bottom: 20px;
    }

    .partners-images-main-container {
        min-height: 400px;
    }

    .partners-images-section-top-left {
        position: absolute;
        top: 0;
        left: 0;

        img {
            max-height: 113px
        }
    }

    .partners-images-section-top-middle {
        position: absolute;
        right: 25%;
        top: 10%;

        img {
            max-height: 38px
        }
    }

    .partners-images-section-top-right {
        position: absolute;
        right: 0;
        top: 20%;

        img {
            max-height: 29px
        }
    }

    .partners-images-section-mid-left {
        position: absolute;
        left: 0;
        top: 45%;

        img {
            max-height: 29px;
        }
    }

    .partners-images-section-mid-middle {
        position: absolute;
        right: 40%;
        top: 40%;

        img {
            max-height: 26px;
        }
    }

    .partners-images-section-mid-right {
        position: absolute;
        right: 0;
        top: 40%;

        img {
            max-height: 87px;
        }
    }

    .partners-images-section-bottom-left {
        position: absolute;
        left: 40px;
        bottom: 0;

        img {
            max-height: 116px;
        }
    }

    .partners-images-section-bottom-middle {
        position: absolute;
        right: 20%;
        bottom: 10%;

        img {
            max-height: 100px;
        }
    }

    .partners-images-section-bottom-right {
        position: absolute;
        right: 0;
        bottom: 0;

        img {
            max-height: 37px;
        }
    }

    .solutions-heading-container {
        margin-bottom: 25px;
    }

    .vendor-heading-container {
        margin-bottom: 25px;
    }

    .industries-heading-container {
        margin-bottom: 50px;
    }

    .industries-container {
        margin-right: 20px;
        margin-bottom: 25px;
    }

    .vendor-main-information-container {
        background-image: url("/public/images/vendor-circle.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 60%;
        height: 500px;
    }

    .position-first {
        position: absolute;
        top: 5%;
        left: 5%;
        box-shadow: 10px 10px 10px 0 #3B87AF;
    }

    .position-second {
        position: absolute;
        top: 35%;
        right: 10%;
        box-shadow: 10px 10px 10px 0 #3B87AF;
    }

    .position-last {
        position: absolute;
        bottom: 5%;
        left: 15%;
        box-shadow: 10px -10px 10px 0 #3B87AF;
    }
}

/* -- X-Large Screen Breakpoint -- */
@media (min-width: 1200px) {
    .banner-heading-container {
        margin-bottom: 32px;
    }

    .banner-description {
        font-size: 32px;
        margin-bottom: 32px;
    }

    .call-to-action-button-container {
        display: inline-flex;
    }

    .service-icon-container {
        margin-bottom: 20px;
    }

    .service-header-container {
        margin-bottom: 20px;
    }

    .partners-heading-container {
        margin-bottom: 20px;
    }

    .partners-images-main-container {
        min-height: 400px;
    }

    .partners-images-section-top-left {
        position: absolute;
        top: 0;
        left: 0;

        img {
            max-height: 163px
        }
    }

    .partners-images-section-top-middle {
        position: absolute;
        right: 25%;
        top: 10%;

        img {
            max-height: 48px
        }
    }

    .partners-images-section-top-right {
        position: absolute;
        right: 0;
        top: 20%;

        img {
            max-height: 39px
        }
    }

    .partners-images-section-mid-left {
        position: absolute;
        left: 0;
        top: 45%;

        img {
            max-height: 39px;
        }
    }

    .partners-images-section-mid-middle {
        position: absolute;
        right: 35%;
        top: 40%;

        img {
            max-height: 36px;
        }
    }

    .partners-images-section-mid-right {
        position: absolute;
        right: 0;
        top: 40%;

        img {
            max-height: 97px;
        }
    }

    .partners-images-section-bottom-left {
        position: absolute;
        left: 40px;
        bottom: 0;

        img {
            max-height: 136px;
        }
    }

    .partners-images-section-bottom-middle {
        position: absolute;
        right: 30%;
        bottom: 15%;

        img {
            max-height: 110px;
        }
    }

    .partners-images-section-bottom-right {
        position: absolute;
        right: 0;
        bottom: 0;

        img {
            max-height: 37px;
        }
    }

    .solutions-heading-container {
        margin-bottom: 25px;
    }

    .vendor-heading-container {
        margin-bottom: 25px;
    }

    .industries-heading-container {
        margin-bottom: 50px;
    }

    .industries-container {
        margin-right: 20px;
        margin-bottom: 25px;
    }

    .vendor-main-information-container {
        background-image: url("/public/images/vendor-circle.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 40%;
        height: 500px;
    }

    .position-first {
        position: absolute;
        top: 10%;
        left: 15%;
        box-shadow: 10px 10px 10px 0 #3B87AF;
    }

    .position-second {
        position: absolute;
        top: 30%;
        right: 15%;
        box-shadow: 10px 10px 10px 0 #3B87AF;
    }

    .position-last {
        position: absolute;
        bottom: 15%;
        left: 20%;
        box-shadow: 10px -10px 10px 0 #3B87AF;
    }
}

/* -- XX-Large Screen Breakpoint -- */
@media (min-width: 1400px) {
    .banner-heading-container {
        margin-bottom: 32px;
    }

    .banner-description {
        font-size: 32px;
        margin-bottom: 32px;
    }

    .call-to-action-button-container {
        display: inline-flex;
    }

    .partners-images-section-top-left {
        position: absolute;
        top: 0;
        left: 0;

        img {
            max-height: 163px;
            height: 163px;
            width: 250px;
        }
    }

    .partners-images-section-top-middle {
        position: absolute;
        right: 25%;
        top: 10%;

        img {
            max-height: 48px
        }
    }

    .partners-images-section-top-right {
        position: absolute;
        right: 0;
        top: 20%;

        img {
            max-height: 39px
        }
    }

    .partners-images-section-mid-left {
        position: absolute;
        left: 0;
        top: 45%;

        img {
            max-height: 39px;
        }
    }

    .partners-images-section-mid-middle {
        position: absolute;
        right: 35%;
        top: 40%;

        img {
            max-height: 36px;
        }
    }

    .partners-images-section-mid-right {
        position: absolute;
        right: 0;
        top: 40%;

        img {
            max-height: 97px;
        }
    }

    .partners-images-section-bottom-left {
        position: absolute;
        left: 40px;
        bottom: 0;

        img {
            max-height: 136px;
        }
    }

    .partners-images-section-bottom-middle {
        position: absolute;
        right: 30%;
        bottom: 15%;

        img {
            max-height: 110px;
        }
    }

    .partners-images-section-bottom-right {
        position: absolute;
        right: 0;
        bottom: 0;

        img {
            max-height: 37px;
        }
    }

    .solutions-heading-container {
        margin-bottom: 25px;
    }

    .vendor-heading-container {
        margin-bottom: 25px;
    }

    .industries-heading-container {
        margin-bottom: 50px;
    }

    .industries-container {
        margin-right: 20px;
        margin-bottom: 25px;
    }

    .vendor-main-information-container {
        background-image: url("/public/images/vendor-circle.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 40%;
        height: 500px;
    }

    .position-first {
        position: absolute;
        top: 10%;
        left: 15%;
        box-shadow: 10px 10px 10px 0 #3B87AF;
    }

    .position-second {
        position: absolute;
        top: 30%;
        right: 15%;
        box-shadow: 10px 10px 10px 0 #3B87AF;
    }

    .position-last {
        position: absolute;
        bottom: 15%;
        left: 20%;
        box-shadow: 10px -10px 10px 0 #3B87AF;
    }
}
.partner-cls-set
{
    width: 100%;
    height: 100%;
}