.accordion-main-container {
    border: 0 !important;
    box-shadow: none !important;
}

.accordion-button-container {
    border: 0 !important;
    border-radius: 5px !important;
    color: var(--default-text-color) !important;
    background-color: var(--white-color) !important;
    padding: 15px 0 !important;
}

.legal-description-container {
    span {
        font-family: var(--font-family) !important;
        line-height: 1.5em;
        color: var(--default-text-color) !important;
    }
}

.accordion-body-container {
    padding-left: 0 !important;

    .table {
        border: 0 !important;

        table {
            width: 100%;
            margin-bottom: 1rem;
            vertical-align: top;
            overflow-x: auto;
        }

        tr {
            border-bottom: 1px solid var(--border-color) !important;
        }

        td {
            padding: 15px !important;
            border-right: 1px solid var(--border-color) !important;

            span {
                font-family: var(--font-family) !important;
                line-height: 1.5em;
                color: var(--default-text-color) !important;
            }
        }

        td:first-child {
            padding-left: 0 !important;
        }

        td:last-child {
            border-right: 0 !important;
        }

        tr:last-child {
            border-bottom: 0 !important;
        }
    }

    p {
        a {
            cursor: pointer;
            text-decoration: underline !important;
            color: var(--primary-color) !important;
        }

        span {
            font-family: var(--font-family) !important;
            line-height: 1.5em;
            color: var(--default-text-color) !important;
        }
    }

    li {
        color: var(--default-text-color) !important;

        span {
            font-family: var(--font-family) !important;
            line-height: 1.5em;
            color: var(--default-text-color) !important;
        }
    }
}

/* -- Media Queries -- */
@media (max-width: 576px) {
    .legal-heading-container {
        margin-bottom: 30px;
    }
}

/* -- Medium Screen Breakpoint -- */
@media (min-width: 768px) {
    .legal-heading-container {
        margin-bottom: 40px;
    }
}

/* -- Large Screen Breakpoint -- */
@media (min-width: 992px) {
    .legal-heading-container {
        margin-bottom: 40px;
    }
}

/* -- X-Large Screen Breakpoint -- */
@media (min-width: 1200px) {
    .legal-heading-container {
        margin-bottom: 40px;
    }
}

/* -- XX-Large Screen Breakpoint -- */
@media (min-width: 1400px) {
    .legal-heading-container {
        margin-bottom: 40px;
    }
}