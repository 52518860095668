.contact-us {
    position: relative;
}

.header-section {
    background-color: var(--secondary-color);
    color: var(--white-color);
    padding: 20px;
    position: relative;
    z-index: -1;
}

.breadcrumb {
    border: 1px solid var(--secondary-color);
    display: inline-block;
    padding: 5px 10px;
    margin: 20px 0;
    margin-bottom: 5%;
    background: var(--contact-us-breadcrumb-background);
    color: var(--white-color);
    padding-bottom: 2%;
}

.main-heading {
    margin: 10px 0;
    font-weight: 700 !important;
    text-transform: none;
    font-style: normal;
    letter-spacing: 0;
    word-spacing: 0;
}

.info-sections {
    background: var(--contact-us-section-bg-color);
    padding: 20px;
    padding-bottom: 135px; /* Adjust this if necessary */
}

.list-section {
    display: flex;
    flex-wrap: wrap;
}

.list-section div {
    align-items: center;
}

.list-section span::before {
    content: '✔️';
    margin-right: 10px;
}

.contact-form {
    width: 40%;
    padding: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    margin-top: -41px;
    margin-right: 2%;
}

.line-with-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%; /* Adjust as needed */
    margin: 20px 0; /* Adjust as needed */
}

.line {
    width: 40%; /* Adjust as needed */
    border: 1px solid #ccc;
    margin: 0;
}

.icon {
    position: absolute;
    background: var(--white-color); /* Same as the background color of the container to cover the line */
    padding: 0 10px; /* Adjust the padding to create space around the icon */
    color: #000; /* Adjust the color as needed */
}

.icon svg {
    width: 24px; /* Adjust the size of the icon as needed */
    height: 24px; /* Adjust the size of the icon as needed */
}

.circle {

    align-items: center;
    justify-content: center;
    width: 29px;
    height: 27px;
    border-radius: 57%;
    background-color: var(--secondary-color);
    margin-right: 10px;
}

.circle::before {
    content: '✔️';
    color: white;
    font-size: 14px;
    line-height: 1;
    margin: 7px;
}

.list-section .service-text {
    font-size: 18px; /* Adjust the font size */
    margin-bottom: 10px; /* Add spacing between services */
}

.contact-section {
    font-size: 18px;

    a {
        color: #B22234;
        text-decoration: none;
    }
}

.contact-us-heading-container {
    margin-bottom: 20px;
}

.contact-us-information-container {
    margin-bottom: 20px;
}

.support-information {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5em;

    span {
        display: block;

        a {
            color: #212529;
            text-decoration: none;
        }
    }
}

.contact-us-icon {
    border-left: 3px solid var(--border-color);
    margin-left: 20px;
    margin-right: 20px;
}

.contact-us-button-container {
    margin-bottom: 20px;
}

.contact-us-image-container {
    img {
        border-radius: 5px;
    }
}

@media (max-width: 768px) {
    .breadcrumb {
        font-size: 14px;
        margin-bottom: 5%;
    }

    .main-heading {
        font-size: 20px;
    }

    .list-section .circle::before {
        font-size: 12px;
        margin: 4px;
    }

    .steps-section {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column; /* Stack steps vertically on mobile */
    }

    .step {
        flex-direction: column; /* Each step stacks vertically */
        margin-bottom: 10px;
    }

    .step-number {
        font-weight: bold;
        margin-right: 0;
    }

    .step-description {
        flex-grow: 1;
        padding-left: 10px;
        padding-top: 5px;
    }

    .content-wrapper {
        position: relative;
    }

    .contact-form {
        position: relative;
        margin-top: 20px;
        width: 100%;
        top: auto;
        right: auto;
        transform: none;
        margin-bottom: 20px; /* Add margin bottom to create space for the footer */
    }

    .list-section {
        flex-direction: column;
    }

    .list-section .col-md-6 {
        width: 100%;
    }
}

@media (min-width: 769px) {
    .contact-form {
        position: absolute;
        top: 67%;
        right: 0;
        transform: translate(0, -50%);
        width: 40%;
        margin-top: -100px;
        margin-right: 2%;
    }

    .list-section {
        display: flex;
        flex-wrap: wrap;
    }

    .list-section .col-md-6 {
        flex: 0 0 calc(50% - 20px); /* Adjust width based on the number of steps */
        margin-right: 20px;
    }

    .steps-section {
        display: flex;

        flex-direction: row; /* Display steps in a row on desktop */
    }

    .step {
        flex-direction: row; /* Each step in a row */
        align-items: flex-start;
        margin-bottom: 10px;
    }

    .step-number {
        font-weight: bold;
        margin-right: 10px;
        white-space: nowrap; /* Prevent step number from wrapping */
    }

    .step-description {
        flex-grow: 1;
        padding-left: 10px;
        padding-top: 5px;
    }
}

.step {
    display: flex;
    flex-direction: row; /* Ensure steps are in a row */
    margin-bottom: 10px;
}

.step-number {
    font-weight: bold;
    margin-right: 10px;
}

.step-description {
    flex-grow: 1;
}

.steps-section .step-description {
    flex-grow: 1;
    padding-left: 10px;
    padding-top: 5px;
    font-size: larger;
}

.steps-section .step-number {
    font-weight: bold;
    margin-right: 5px;
    display: inline-block;
    width: 30px;
    text-align: center;
    font-size: 2em;
}

.steps-section .step-description {
    flex-grow: 1;
    padding-left: 10px;
    padding-top: 5px;
    font-size: medium;
}

@media (max-width: 768px) {
    .step {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    .step-number {
        margin-right: 10px;
        font-weight: bold;
    }
}

@media (min-width: 769px) {
    .steps-section {
        display: flex;
        flex-wrap: wrap;
    }

    .step {
        flex: 0 0 calc(33.333% - 20px); /* Adjust width based on the number of steps */
        margin-right: 20px;
        align-items: center;
        margin-bottom: 10px;
    }

    .step:last-child {
        margin-right: 0; /* Remove margin from the last step */
    }

    .step-number {
        margin-right: 10px;
        font-weight: bold;
    }
}

.step {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.step-number {
    font-weight: bold;
    margin-right: 10px;
}

.step-description {
    flex-grow: 1;
}

.steps-section .step-description {
    flex-grow: 1;
    padding-left: 10px; /* Add padding to the left of the text */
    padding-top: 5px; /* Add top padding to the text */
    align-items: center;
    justify-content: center;
    font-size: larger;
}

.steps-section .step-number {
    font-weight: bold;
    margin-right: 5px;
    display: inline-block;
    width: 30px;
    text-align: center;
    font-size: 2em; /* Increase the font size of the step number */
}

.steps-section .step-description {
    flex-grow: 1;
    padding-left: 10px;
    padding-top: 5px;
    align-items: center;
    justify-content: center;
    font-size: medium;

}

.steps-section {
    display: flex;
    flex-direction: column;
}

.step {
    display: flex;
    flex-direction: column; /* Stack items vertically by default */
    margin-bottom: 1rem; /* Space between steps */
}

.step-number, .step-description {
    margin: 0; /* Ensure no additional margins */
    padding: 0; /* Ensure no additional paddings */
}

@media (min-width: 768px) {
    .steps-section {
        flex-direction: row; /* Arrange items in a row on larger screens */
        flex-wrap: wrap; /* Allow wrapping to next line if needed */
    }

    .step {
        flex-direction: row; /* Arrange step number and description next to each other */
        align-items: flex-start; /* Align items at the start */

    }

    .main-heading {
        font-size: 25px !important;
        line-height: 1.2em !important;
    }
}

/* -- Media Queries -- */
@media (max-width: 576px) {
    .contact-us-information-container {
        text-align: center;
    }

    .main-heading {
        font-size: 25px !important;
        line-height: 1.2em !important;
    }
}

/* -- Medium Screen Breakpoint -- */
@media (min-width: 768px) {
    .contact-us-information-container {
        text-align: left;
        display: inline-flex;
    }

    .main-heading {
        font-size: 30px !important;
        line-height: 1.2em !important;
    }
}

/* -- Large Screen Breakpoint -- */
@media (min-width: 992px) {
    .contact-us-information-container {
        text-align: left;
        display: inline-flex;
    }

    .main-heading {
        font-size: 30px !important;
        line-height: 1.2em !important;
    }
}

/* -- X-Large Screen Breakpoint -- */
@media (min-width: 1200px) {
    .contact-us-information-container {
        text-align: left;
        display: inline-flex;
    }

    .main-heading {
        font-size: 48px !important;
        line-height: 1.2em !important;
    }
}

/* -- XX-Large Screen Breakpoint -- */
@media (min-width: 1400px) {
    .contact-us-information-container {
        text-align: left;
        display: inline-flex;
    }

    .main-heading {
        font-size: 48px !important;
        line-height: 1.2em !important;
    }
}