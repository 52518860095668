@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Michroma&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

html {
    --primary-color: #B22234;
    --secondary-color: #002868;
    --facebook-color: #1877F2;
    --linkedin-color: #0077b5;
    --twitter-color: #1DA1F2;
    --border-color: #d9d9d9;
    --link-active-color: #B22234;
    --button-default-color: #FFFFFF;
    --default-text-color: #242627;
    --white-color: #FFFFFF;
    --paragraph-text-color: #abb8c3;
    --product-card-background-color: #002868;
    --contact-us-breadcrumb-background: #00000026;
    --contact-us-section-bg-color: linear-gradient(90deg, #E9E6EF 0%, rgba(217, 217, 217, 0.5) 100%);
    --font-family: "Inter Tight", sans-serif !important;
    --default-badge-background-color: #F5F5F5;
    --success-message-background-color: rgb(188, 248, 188);
    --related-services-light-background-color: #b2f5f8;
}

body {
    font-family: var(--font-family) !important;
}

/* -- Margins -- */
.mr-auto {
    margin-right: auto !important;
}

.ml-auto {
    margin-left: auto !important;
}

/* -- Borders -- */
.border-bottom-1 {
    border-bottom: 1px solid var(--border-color);
}

.border-right-1 {
    border-left: 2px solid var(--border-color);
}

.border-radius-0 {
    border-radius: 0 !important;
}

/* -- Text -- */
.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

/* -- Headings -- */
h1, .h1 {
    font-weight: 600 !important;
    text-transform: none;
    font-style: normal;
    letter-spacing: -1px;
    word-spacing: 0;
}

h2, .h2 {
    font-weight: 700 !important;
    text-transform: none;
    font-style: normal;
    letter-spacing: 0;
    word-spacing: 0;
}

h3, .h3 {
    font-weight: 600;
    text-transform: none;
    font-style: normal;
    letter-spacing: -1px;
    word-spacing: 0;
}

h4, .h4 {
    font-weight: 600 !important;
    text-transform: none;
    font-style: normal;
    letter-spacing: 0;
    word-spacing: 0;
}

h5, .h5 {
    font-weight: 600 !important;
    text-transform: none;
    font-style: normal;
    letter-spacing: 0;
    word-spacing: 0;
}

/* -- Buttons -- */
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--primary-color) !important;
    --bs-btn-border-color: var(--primary-color) !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--primary-color) !important;
    --bs-btn-hover-border-color: var(--primary-color) !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--primary-color) !important;
    --bs-btn-active-border-color: var(--primary-color) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--primary-color) !important;
    --bs-btn-disabled-border-color: var(--primary-color) !important;
    font-size: 14px !important;
    line-height: 1.2em !important;
    letter-spacing: 0 !important;
    word-spacing: 0 !important;
    border-radius: 4px !important;
    padding: 14px 24px !important;
    font-weight: 600 !important;
}

.btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--secondary-color) !important;
    --bs-btn-border-color: var(--secondary-color) !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--secondary-color) !important;
    --bs-btn-hover-border-color: var(--secondary-color) !important;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--secondary-color) !important;
    --bs-btn-active-border-color: var(--secondary-color) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--secondary-color) !important;
    --bs-btn-disabled-border-color: var(--secondary-color) !important;
    font-size: 14px !important;
    line-height: 1.2em !important;
    letter-spacing: 0 !important;
    word-spacing: 0 !important;
    border-radius: 4px !important;
    padding: 14px 24px !important;
    font-weight: 600 !important;
}

.btn-secondary.disabled {
    color: #fff;
    background: #00286854;
    font-size: 14px !important;
    line-height: 1.2em !important;
    letter-spacing: 0 !important;
    word-spacing: 0 !important;
    border-radius: 4px !important;
    padding: 14px 24px !important;
    font-weight: 600 !important;
}

.btn-default {
    --bs-btn-color: var(--default-text-color);
    --bs-btn-bg: var(--button-default-color) !important;
    --bs-btn-border-color: var(--button-default-color) !important;
    --bs-btn-hover-color: var(--secondary-color);
    --bs-btn-hover-bg: var(--button-default-color) !important;
    --bs-btn-hover-border-color: var(--button-default-color) !important;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: var(--secondary-color);
    --bs-btn-active-bg: var(--button-default-color) !important;
    --bs-btn-active-border-color: var(--button-default-color) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--secondary-color);
    --bs-btn-disabled-bg: var(--button-default-color) !important;
    --bs-btn-disabled-border-color: var(--button-default-color) !important;
    font-size: 14px !important;
    line-height: 1.2em !important;
    letter-spacing: 0 !important;
    word-spacing: 0 !important;
    border-radius: 4px !important;
    padding: 14px 24px !important;
    font-weight: 600 !important;
}

.btn-primary-link {
    font-size: 14px !important;
    line-height: 1.2em !important;
    letter-spacing: 0 !important;
    word-spacing: 0 !important;
    font-weight: 600 !important;
    color: var(--secondary-color);
}

/* -- Table -- */
.table-primary {
    --bs-table-color: var(--white-color) !important;
    --bs-table-bg: var(--secondary-color) !important;
    --bs-table-border-color: var(--secondary-color) !important;
    --bs-table-striped-bg: var(--secondary-color) !important;
    --bs-table-striped-color: var(--white-color) !important;
    --bs-table-active-bg: var(--secondary-color) !important;
    --bs-table-active-color: var(--white-color) !important;
    --bs-table-hover-bg: var(--secondary-color) !important;
    --bs-table-hover-color: var(--primary-color) !important;
    color: var(--white-color);
    border-color: var(--secondary-color);
}

/* -- Cards -- */
.card {
    border-radius: 5px !important;
}

.card-body {
    background-color: #FFFFFF !important;
    padding: 20px !important;
}

.card-footer {
    background-color: #FFFFFF !important;
    padding: 15px 20px !important;
}

/* -- Custom -- */
.content-middle {
    align-items: center;
    justify-content: center;
    display: inline-flex;
}

.section-spacing {
    padding-top: 50px;
    padding-bottom: 50px;
}

.powered-by {
    display: none !important;
}

.fs-webform-container .fserv-form-name {
    font-size: medium !important;
}

.fs-webform-container .fserv-field + div:not(.fserv-field) {
    border-top: none !important;
}

.fserv-form-name {
    position: relative; /* Ensure relative positioning for containing pseudo-element */
    font-size: medium !important;
}


.section-spacing-top {
    margin-top: 50px !important;
}

.section-spacing-bottom {
    margin-bottom: 50px !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

/* cookie management */
.cookie-consent {
    position: fixed;
    bottom: 20px; /* Adjust as needed */
    left: 20px !important; /* Adjust as needed */
    background-color: var(--contact-us-breadcrumb-background);
    color: var(--button-default-color);
    padding: 10px 20px;
    border-radius: 8px;
    z-index: 9999; /* Ensure it stays on top */
    max-width: 360px; /* Set max width for the block */
    box-shadow: 0 0 10px var(--contact-us-breadcrumb-background);
    width: 350px !important;
    height: 300px !important;

}

.cookie-consent-button {
    margin-top: 10px;
    background-color: var(--secondary-color) !important;
    color: var(--button-default-color) !important;
    border: var(--secondary-color);
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 10px !important;
    width: 110px;
    height: 50px;
}

.cookie-consent-decline-button {
    margin-top: 10px;
    background-color: var(--button-default-color) !important;
    color: var(--secondary-color) !important;
    border: var(--secondary-color);
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 10px !important;
    width: 110px;
    height: 50px;
}

.cookie-consent-buttons {
    display: flex;
    justify-content: space-between;
    border-radius: 10px !important;
}

.error-description-container {
    font-size: 20px;
    line-height: 1.5em;
    font-weight: 400;
    color: var(--default-text-color);
}

.default-loading-page {
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
    left: 0;
    right: 0;
}

/* -- Media Queries -- */
@media (max-width: 576px) {
    .text-xs-center {
        text-align: center !important;
    }

    h1, .h1 {
        font-size: 30px !important;
        line-height: 1em !important;
    }

    h4, .h4 {
        font-size: 18px !important;
        line-height: 1.4em !important;
    }
}

/* -- Small Screen Breakpoint -- */
@media (min-width: 576px) {
    .text-sm-center {
        text-align: center !important;
    }

    h1, .h1 {
        font-size: 30px !important;
        line-height: 1em !important;
    }

    h2, .h2 {
        font-size: 25px !important;
        line-height: 1.3em !important;
    }

    h3, .h3 {
        font-size: 22px !important;
        line-height: 1.2em !important;
    }

    h4, .h4 {
        font-size: 18px !important;
        line-height: 1.4em !important;
    }

    h5, .h5 {
        font-size: 16px !important;
        line-height: 1.3em !important;
    }
}

/* -- Medium Screen Breakpoint -- */
@media (min-width: 768px) {
    h1, .h1 {
        font-size: 35px !important;
        line-height: 1em !important;
    }

    h2, .h2 {
        font-size: 30px !important;
        line-height: 1.3em !important;
    }

    h3, .h3 {
        font-size: 26px !important;
        line-height: 1.2em !important;
    }

    h4, .h4 {
        font-size: 22px !important;
        line-height: 1.1em !important;
    }

    h5, .h5 {
        font-size: 18px !important;
        line-height: 1.3em !important;
    }
}

/* -- Large Screen Breakpoint -- */
@media (min-width: 992px) {
    h1, .h1 {
        font-size: 35px !important;
        line-height: 1em !important;
    }

    h2, .h2 {
        font-size: 30px !important;
        line-height: 1.3em !important;
    }

    h3, .h3 {
        font-size: 26px !important;
        line-height: 1.2em !important;
    }

    h4, .h4 {
        font-size: 22px !important;
        line-height: 1.3em !important;
    }

    h5, .h5 {
        font-size: 18px !important;
        line-height: 1.3em !important;
    }
}

/* -- X-Large Screen Breakpoint -- */
@media (min-width: 1200px) {
    h1, .h1 {
        font-size: 55px !important;
        line-height: 1em !important;
    }

    h2, .h2 {
        font-size: 48px !important;
        line-height: 1.2em !important;
    }

    h3, .h3 {
        font-size: 35px !important;
        line-height: 1.2em !important;
    }

    h4, .h4 {
        font-size: 24px !important;
        line-height: 1.3em !important;
    }

    h5, .h5 {
        font-size: 20px !important;
        line-height: 1.3em !important;
    }
}

/* -- XX-Large Screen Breakpoint -- */
@media (min-width: 1400px) {
    h1, .h1 {
        font-size: 55px !important;
        line-height: 1em !important;
    }

    h2, .h2 {
        font-size: 48px !important;
        line-height: 1.2em !important;
    }

    h3, .h3 {
        font-size: 35px !important;
        line-height: 1.2em !important;
    }

    h4, .h4 {
        font-size: 24px !important;
        line-height: 1.3em !important;
    }

    h5, .h5 {
        font-size: 20px !important;
        line-height: 1.3em !important;
    }
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 1000; /* Ensure it's above other content */
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    border: 8px solid rgba(255, 255, 255, 0.3); /* Light semi-transparent border */
    border-radius: 50%;
    border-top: 8px solid #ffffff; /* White spinner */
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite; /* Rotate animation */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-opacity {
    opacity: 0.3; /* Opacity for the rest of the site */
    transition: opacity 0.3s ease; /* Smooth transition */
}

.loading-opacity.loading {
    pointer-events: none; /* Disable interactions */
}
  