/*Primary Blog Component*/
.text-center {
    text-align: center;
}

.PrimaryBlogImage {
    border-radius: 5px;
}

.blog-main-description {
    color: var(--default-text-color);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4em;
    margin-bottom: 20px;
}

.blog-description {
    color: var(--paragraph-text-color)
}

.primary-blog-container {
    padding: 50px;
    border-radius: 10px;
    background-color: var(--default-badge-background-color);
}

/* -- Media Queries -- */
@media (max-width: 576px) {
    .banner-heading-container {
        margin-bottom: 15px;
    }

    .blog-description {
        margin-bottom: 15px;
    }

    .blog-main-heading {
        margin-bottom: 20px;
    }
}

/* -- Medium Screen Breakpoint -- */
@media (min-width: 768px) {
    .banner-heading-container {
        margin-bottom: 20px;
    }

    .blog-description {
        margin-bottom: 20px;
    }

    .blog-main-heading {
        margin-bottom: 20px;
    }
}

/* -- Large Screen Breakpoint -- */
@media (min-width: 992px) {
    .banner-heading-container {
        margin-bottom: 20px;
    }

    .blog-description {
        margin-bottom: 20px;
    }

    .blog-main-heading {
        margin-bottom: 25px;
    }
}

/* -- X-Large Screen Breakpoint -- */
@media (min-width: 1200px) {
    .banner-heading-container {
        margin-bottom: 20px;
    }

    .blog-description {
        margin-bottom: 20px;
    }

    .blog-main-heading {
        margin-bottom: 25px;
    }
}

/* -- XX-Large Screen Breakpoint -- */
@media (min-width: 1400px) {
    .banner-heading-container {
        margin-bottom: 20px;
    }

    .blog-description {
        margin-bottom: 20px;
    }

    .blog-main-heading {
        margin-bottom: 25px;
    }
}

/*Secondary Blog Component*/
.blog-card-body {
    border-radius: 15px !important;
}

.Secondary-Blog-Summary {
    font-size: 16px;
    margin-bottom: 20px;
}

.secondary-blog-image {
    border-radius: 10px;
    max-height: 200px;
}

.blog-badge {
    padding: 7px 9px 6px;
    border-radius: 5px;
    background-color: var(--default-badge-background-color);
    color: var(--default-text-color);
    font-size: 12px;
    font-weight: 600;
    line-height: 1.7em;
    text-transform: uppercase;
}

.Read-More a {
    color: var(--default-text-color);
    font-size: 12px;
    text-decoration: none;
}

.Read-More a:hover {
    color: var(--primary-color);
}

.load-more-btn {
    border-color: var(--border-color);
    border-width: 1px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.load-more-btn:hover {
    color: var(--primary-color);
    font-weight: 600;
    text-decoration: underline;
}

.blog-image-container {
    margin-bottom: 20px;
}

.blog-category-container {
    margin-bottom: 15px;
}

.blog-heading-container {
    margin-bottom: 20px;

    a {
        text-decoration: none;
        color: inherit;
    }
}

  