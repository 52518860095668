.header-main-container {
    background: #FFFFFF;
    position: sticky;
    top: 0;
    z-index: 1;
}

.nav-logo {
    max-height: 70px;
}

.navbar-brand {
    height: 70px;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    color: var(--link-active-color);
    font-weight: 600;
}

.nav-link:focus,
.nav-link:hover {
    color: var(--link-active-color);
}

.dropdown-menu {
    margin-top: 12px !important;
}

.dropdown-menu > li > a.dropdown-item {
    padding: 10px 20px !important;
}

.dropdown-item:active {
    background-color: var(--link-active-color) !important;
}

.dropdown-item.focus,
.dropdown-item:hover {
    color: #ffffff !important;
    background-color: var(--link-active-color) !important;
}

.call-to-action:last-child {
    margin-right: 0 !important;
}

.shopping-cart-icon {
    color: var(--default-text-color);
    font-size: 25px;
    text-decoration: none;
}

.shopping-cart-container[data-badge]:after {
    content: attr(data-badge);
    position: absolute;
    top: -40%;
    right: 15%;
    display: grid;
    justify-content: center;
    align-content: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: none;
    background-color: var(--secondary-color);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .25);
    color: var(--white-color);
    font-size: 10px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
}

.portal-sidebar-container {
    .portal-sidebar-icons {
        max-height: 25px;
        color: var(--default-text-color);
    }

    .portal-sidebar-items {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.4em;
        color: var(--default-text-color);
    }
}

.sidebar-logout-container {
    position: absolute;
    bottom: 0;
    padding-bottom: 15px;
}

.sidebar-logout-icon {
    font-size: 25px;
    color: var(--secondary-color);
}

/* -- Media Queries -- */
@media (max-width: 576px) {
    .logo-sidebar {
        padding-top: 12px;
    }
}

/* -- Medium Screen Breakpoint -- */
@media (min-width: 768px) {

}

/* -- Large Screen Breakpoint -- */
@media (min-width: 992px) {
    .portal-nav-item {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

/* -- X-Large Screen Breakpoint -- */
@media (min-width: 1200px) {
    .portal-nav-item {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

/* -- XX-Large Screen Breakpoint -- */
@media (min-width: 1400px) {
    .portal-nav-item {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}