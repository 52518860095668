.logo-dell
{
    width: 50%;
}
.logo-center {
    max-width: 150px;
    margin-bottom: 10px;
  }

  .main-div
  {
    background-color: var(--related-services-light-background-color);
    border-radius: 10px;
    padding: 17px;
  }
  .text-muted.small {
    display: block;
    margin-top: 5px;
  }
  .custom-success-message {
    background-color: var(--success-message-background-color);
  }
  